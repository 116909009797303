import React from 'react'

const Svgdefs = () => {
  return (
    <div>
      <svg display="none" width="0" height="0" version="1.1">
        <defs>
          <symbol id="icon-kan-logo" viewBox="0 0 411.6 92.9">
            <title>Karl-Åke Nyström</title>
            <path
              d="M183,62.3c-0.8-0.3-1.8-0.3-2.6-0.4c-2.6,3.1-5.7,5.5-10.2,4.2c-2.9-0.9-3.7-4.5-5.4-6.6c-2-2.4-4.8-5.5-7.7-2.5
	c-1.4,1.5-3.7,1.7-5.5,0.9c-1-0.4-1.9-1.1-2.6-2c-1.1-1.5-2.4-1.3-4.3-1.5c-1.8,1.3-0.7,5.2-3.8,5.3c-2.9,0.1-5-1.9-3.5-4.8
	c1.8-3.3,0.5-5.8-0.7-9.1c-1-2.8-2.3-5.6,0.1-8.1c3-3,5.9-5.3,7.8-9.2c0-0.2-0.1-0.5-0.2-0.7c-4.1-4.4-4.2,2.3-6.2,4.2
	c-3.1,3-9.8-0.7-13.1-2.1c-6.8-2.9-11.4-8.3-16.3-13.6c2.9-0.7,5.4-1.5,8.4-1.4c3,0.1,5.9,1.2,8.8,1.8c2.3,0.5,4.8,1.1,5.7,3.6
	c0.4-4.3-3.8-3.4-6.9-4.6c-5.4-2-11.8-5.4-17.7-3.8c-1.2,2.6,1.4,6.4,2.9,8.5c2.3,3.1,6.1,5.1,9,7.5c1.2,1,2.3,2.1,3.5,3.1
	c1.2,1,2.9,1.3,3.9,2.4c3,3.1,2.4,6.4,2.7,10.5c0.2,2.8-0.1,5.2,0.3,8c0.5,4.1-4.5,6.3-7.3,8.1c-2.5,1.7-5.5,3.9-8.6,2.4
	c-3.2-1.6-5.7-3.5-9.3-4.3c-4.2-0.9-1.5-3.9-2.3-6.3c-1.5-0.4-3.2-0.9-4.8-1c-2-0.2-2,0.4-3.3,1.9c-2.8,3.2-5.3,6.7-9,9.1
	c-1.9,1.2-3,0.4-4.9,0.4c-3.1,0.3-5.8-0.8-8.8-1.4c-1.3-0.3-2.7-0.2-4-0.4c-1.7,0-2.8-1.3-4.3-1.7c-2.9-0.7-7.2-1.6-9.3,1.1
	c-2.6,3.2-4.3,4.3-8.3,5c-4.4,0.7-9.3-0.8-13.3-2.8c-1.6-0.8-3.5-1.3-3.8-3.3c-0.3-2,1-3.3,2.8-3.6c3.2-0.4,6.6-1.4,9.6-2.4
	c3.6-1.2,5.3-3,4.9-7.4c-1.4,0.3-3.1,1.4-3.9,2.5c-1.3,1.7-4.8,2.7-6.7,3.5c-2.6,1.2-13.3,5.3-13.9,0.2c-0.4-2.2,0.4-4.4-0.3-6.5
	c-1-0.1-2.2-0.5-3.2-0.2c-1,1.7-1,4.7-1.3,6.7c-0.4,2.9-0.6,5.7-0.8,8.6c-0.3,4.6-0.1,10.2,5.9,9.6c1.4-0.7,0.4-3.4,2.7-4
	c2.3-0.6,5.5,0.2,7.7,0.7c5.3,0.8,10.8,1.6,16.1,1.6c1.2,0,2.4-0.8,3.7-0.8c1.5,0,2.8,0.8,4.3,0.8c1.5,0,2.4-0.1,3.7-1
	c5.3-3.4,12.7-2.2,17.8,0.4c3.2-0.1,5.9,1.7,9.1,1.5c3.3-4.1,6.2-10.1,12.6-7.1c2.6,1.2,3.5,2,6.5,2.2c3.3,0.2,4.6,2.1,5.4,5
	c3.8,1.5,7.4,1.3,10.3-1.4c2.4-2.2,8.3-6.6,11.8-5.3c3.6,1.2,5,6,9.7,3.2c2-1.2,3.8-2.1,5.9-2.9c2.8-1.9,6.1,0.2,9.2,0.4
	c4.7,0.3,5.5,1.9,8.7,5c0.9,0.9,2,1.9,3.1,2.4c1.9,0.8,3.4-0.4,5.3-0.4c2.8,0,7.8-1.5,9.9-3.5C185.4,64.9,184.5,62.9,183,62.3
	C182.2,62,184.5,62.9,183,62.3z"
            />
            <path d="M318.5,50.8C318.5,50.8,318.5,50.8,318.5,50.8C318.5,50.8,318.5,50.8,318.5,50.8z" />
            <path d="M319.6,50.8c-0.2,0.1-0.3,0.1-0.5,0.1C319.3,50.9,319.4,50.9,319.6,50.8z" />
            <path d="M318.9,50.9C318.9,50.9,318.8,50.9,318.9,50.9C318.8,50.9,318.9,50.9,318.9,50.9z" />
            <path d="M322.7,44.2c0.2,0,0.4-0.1,0.6-0.1C323.1,44.2,322.9,44.2,322.7,44.2z" />
            <path d="M318.3,50.7C318.2,50.6,318.2,50.6,318.3,50.7C318.2,50.6,318.2,50.6,318.3,50.7z" />
            <path d="M322.1,44.4c0.2,0,0.3-0.1,0.5-0.1C322.4,44.3,322.2,44.3,322.1,44.4z" />
            <path d="M323.5,44.2c0,0,0.1,0,0.1,0C323.6,44.2,323.5,44.2,323.5,44.2z" />
            <path d="M321.4,44.5c0,0,0.1,0,0.1,0C321.5,44.5,321.4,44.5,321.4,44.5z" />
            <path d="M317.6,49.9C317.5,49.9,317.5,49.9,317.6,49.9C317.5,49.9,317.5,49.9,317.6,49.9z" />
            <path d="M317.8,50.2C317.8,50.2,317.7,50.2,317.8,50.2C317.7,50.2,317.8,50.2,317.8,50.2z" />
            <path d="M318,50.5C318,50.4,317.9,50.4,318,50.5C317.9,50.4,318,50.4,318,50.5z" />
            <path d="M319.2,48.9c2.2-0.2,5.2,0.3,4.6-2.5C322.7,45.8,317.9,47,319.2,48.9z" />
            <path d="M323.3,44.2C323,44.2,323.9,44.2,323.3,44.2C323.3,44.2,323.4,44.2,323.3,44.2z" />
            <path d="M317.5,49.9c-0.1-0.1-0.1-0.2-0.2-0.3C317.4,49.7,317.4,49.8,317.5,49.9z" />
            <path d="M320.8,44.6c0.2,0,0.4,0,0.6-0.1C321.2,44.6,321,44.6,320.8,44.6z" />
            <path d="M322.6,44.3c0,0,0.1,0,0.1,0C322.7,44.2,322.6,44.3,322.6,44.3z" />
            <path d="M321.5,44.5c0.2,0,0.4-0.1,0.6-0.1C321.9,44.4,321.7,44.5,321.5,44.5z" />
            <path d="M318.2,50.6c-0.1,0-0.1-0.1-0.2-0.1C318.1,50.5,318.1,50.6,318.2,50.6z" />
            <path d="M317.9,50.4c0,0-0.1-0.1-0.1-0.2C317.8,50.3,317.9,50.3,317.9,50.4z" />
            <path d="M323.6,44.2c0.1,0,0.3,0,0.4,0.1C323.9,44.2,323.8,44.2,323.6,44.2z" />
            <path d="M318.5,50.8c-0.1,0-0.2-0.1-0.3-0.2C318.3,50.7,318.4,50.8,318.5,50.8z" />
            <path d="M318.8,50.9c-0.1,0-0.2,0-0.3-0.1C318.6,50.9,318.7,50.9,318.8,50.9z" />
            <path d="M319.1,50.9C319.3,50.9,318.6,50.9,319.1,50.9C319.1,50.9,319,50.9,319.1,50.9z" />
            <path d="M317.7,50.1c0-0.1-0.1-0.1-0.1-0.2C317.6,50,317.7,50.1,317.7,50.1z" />
            <path
              d="M317.5,49.9c1.7,2.6,8.3,0.9,8.6-1.9C326.9,40.9,314.4,45.1,317.5,49.9C317.5,49.9,317.4,49.8,317.5,49.9z M319.2,47.9
	c0.7-1.2,3.3-2.2,4.6-1.5c0.7,2.9-2.4,2.3-4.6,2.5C319,48.5,319,48.2,319.2,47.9C319.8,46.9,319.1,48,319.2,47.9z"
            />
            <path d="M334.2,37.4c0.2,0.1,0.3,0.1,0.5,0.2C334.5,37.5,334.3,37.5,334.2,37.4z" />
            <path d="M335.4,43.2c-0.1,0.1-0.2,0.1-0.3,0.2C335.2,43.4,335.3,43.3,335.4,43.2z" />
            <path d="M333.3,37.2c0.2,0,0.5,0.1,0.7,0.2C333.8,37.3,333.5,37.2,333.3,37.2z" />
            <path d="M334.9,37.7c0.2,0.1,0.4,0.2,0.6,0.4C335.3,37.9,335.1,37.8,334.9,37.7z" />
            <path d="M332.6,37.1c0.2,0,0.5,0,0.7,0C333.1,37.2,332.8,37.1,332.6,37.1z" />
            <path d="M330.9,37.4c0.2-0.1,0.5-0.1,0.7-0.1C331.4,37.2,331.1,37.3,330.9,37.4z" />
            <path d="M331.8,37.2c0.2,0,0.4,0,0.6,0C332.2,37.2,332,37.2,331.8,37.2z" />
            <path d="M335.6,42.8c-0.1,0.1-0.1,0.3-0.2,0.4C335.5,43.1,335.6,43,335.6,42.8z" />
            <path
              d="M331.1,40.3c0.9,0.8,1.7,1.2,2.8,1.3c0.2-0.5,0.3-1,0.5-1.5c-0.1-0.1-0.1-0.3-0.2-0.4c-0.8-0.5-3.1-1-3.6,0.2
	C330.8,40,331,40.2,331.1,40.3z"
            />
            <path d="M334.2,43.6c-0.1,0-0.2,0-0.3,0C334,43.6,334.1,43.6,334.2,43.6z" />
            <path d="M335,43.5c-0.1,0-0.2,0.1-0.3,0.1C334.8,43.5,334.9,43.5,335,43.5z" />
            <path d="M331.6,37.2c0.1,0,0.1,0,0.2,0C331.8,37.2,331.7,37.2,331.6,37.2z" />
            <path d="M333.3,37.2C333.3,37.2,333.3,37.2,333.3,37.2C333.3,37.2,333.3,37.2,333.3,37.2z" />
            <path d="M332.5,37.1C332.1,37.1,332.8,37.1,332.5,37.1C332.5,37.1,332.5,37.1,332.5,37.1z" />
            <path d="M334.7,37.6c0.1,0,0.1,0.1,0.2,0.1C334.8,37.7,334.7,37.6,334.7,37.6z" />
            <path d="M334.3,43.6C334.4,43.6,334.1,43.6,334.3,43.6C334.3,43.6,334.3,43.6,334.3,43.6z" />
            <path d="M335.4,43.2C335.4,43.2,335.4,43.2,335.4,43.2C335.4,43.2,335.4,43.2,335.4,43.2z" />
            <path d="M334.7,43.5C334.7,43.5,334.7,43.5,334.7,43.5C334.7,43.5,334.7,43.5,334.7,43.5z" />
            <path d="M335.1,43.4C335.1,43.4,335.1,43.4,335.1,43.4C335.1,43.4,335.1,43.4,335.1,43.4z" />
            <path d="M334,37.3c0.1,0,0.1,0,0.2,0.1C334.1,37.4,334,37.4,334,37.3z" />
            <path
              d="M329.7,41.7c3.8,3.8,5.7,1.8,6.9-1.8C333.9,35.2,326.3,38.4,329.7,41.7C330.9,42.9,329.1,41.1,329.7,41.7z M331.4,39.3
	c1-0.2,2-0.2,2.9,0.4c0.1,0.1,0.1,0.3,0.2,0.4c-0.2,0.5-0.3,1-0.5,1.5c-1.3,0-2.4-0.7-3.3-1.7C330.8,39.5,331,39.4,331.4,39.3
	C331.8,39.2,331,39.4,331.4,39.3z"
            />
            <path
              className="st0"
              d="M293.3,47.5c-2.5,0.3-6.1,0.7-4.9,4c0.3,1.9,1.2,3.1,2.2,3.5c-0.2-0.1-0.3-0.3-0.4-0.4
	C288.5,52.8,292.2,48.6,293.3,47.5z"
            />
            <path d="M399.3,64.3C399.4,64.3,398,63.9,399.3,64.3L399.3,64.3z" />
            <path
              d="M399.3,64.3c-5.7-1.6-11.1-1.7-17-1.5c-3.3,0.1-6.1-0.8-9.2-1.8c-2.9-0.9-6.1,0.5-9,0.9c-2.9,0.4-6.3-2.9-8.9-2
	c-1.2,0.4-2.6,0.4-3.8,0.9c-1.9,0.8-3.3,2.5-5.2,3.3c-2.9,1.1-5.7-0.7-8.7-0.1c-3.7,0.7-7.2-0.6-9.1-4c-3.5,0.3-3.1,1.2-5.5,3.1
	c-2.1,1.7-5.3,1.8-7.8,2.2c-2,0.3-3.8,0.7-5.9,0.6c-3.3-0.1-3.4-0.3-7.3-2.5c-1.9-0.7-3.6-2.4-4.9-4c0,0-4.3-2.7-6.6-4.5
	c-1-0.5-1.9-1.6-2.2-3.5c-1.2-3.3,2.4-3.7,4.9-4c0.2-0.2,0.3-0.3,0.3-0.3s3.7,0.1,7.3,0c0.8-0.5,1.3-1.4,1.4-2.6
	c-2.9-1-6.1-0.3-9-1.2c-3.8-1.2-0.8-5,0.1-7.1c0,0,0-0.1-0.1-0.1c-2.8,0.7-5.8,3-7.7,5.3c-2.3,2.7-5.6,2-8.7,1.8
	c-5.5-0.3-13-2.1-17.9,1.2c0,0.1,0.1,0.3,0.1,0.5c6.6,1.5,13.1,1.8,19.9,2c3.7,0.1,4.5,2,5.5,5.2c0.9,2.7,2.1,5,3.5,7.5
	c-2,0.7-4.1,1.3-6,2.4c-3.7,2-7.5,4.9-11.6,1.9c-3.1-2.3-6.1-3-9.8-1.1c-4.4,2.4-4.7-2.6-8-3.6c-0.8-0.2-1.2-0.9-1.9-1.2
	c-1.4-0.8-2.9-0.6-4.2-1.7c-1.3-1.1-1.5-2.9-2.7-4.1c-1.1-1.1-3.4-1.5-4.9-1.8c-3.6-0.7-6.7-0.5-9-4.1c-2.4-3.7-0.5-7.6-5.8-8
	c-1.4,5.3,1.2,11-0.9,16.3c-1.6,4.1-7.2,8.7-11.8,6.3c-4.7-2.4-4.9-11-5.8-15.5c-0.5-2.5-1.1-4.9-1.3-7.4c-0.2-2.3-2.5-3.8-2-6.3
	c-0.8-2.1-2.7-0.1-3.1,0.7c-1.3,2.7-0.7,5.6-0.7,8.6c0,3.8,0.6,7.5,0.5,11.3c-0.1,4.1-2,6.3-4.9,8.9c0,0.6,0,1.2,0,1.7
	c1.3,0.3,4.2,1.4,5.6,0.7c1.8-1,2.4-3.2,4.8-3.2c2.6,0,4.4,3.2,6.5,4.3c1.3,0.7,3.8,1.3,5.3,1.7c2.5-0.4,5.1-1.2,7.5-2.2
	c2.4-1.3,2.9-4,3.6-6.6c0.8-3,1.1-6.6,5.1-6.6c2.9,0,5.6,4.2,7.6,6c2.3,2.2,4.4,4,7.8,3.8c2.9-0.1,7,7.1,9.4,9.1
	c2,1.7-7.9,5.3-7.9,7.9c0,2.8,0,3.8,0.9,4.1c2.5,0,6.7-2.6,8.6-4.1c1.4-1.1,3.6-3.2,3.9-5c0.3-1.8,0.1-3.3,1.5-4.7
	c3.1-3,8.1,1.8,11.7,1.8c5.1,0,4.4-6.6,9.5-6.6c1.8-0.1,3.7-0.6,5.4,0.4c1.9,1.1,4,5,6.3,5c4.1,0,8.5,0.1,12.7-0.1
	c1.1-0.1,4.4,0.6,5.1-0.5c1.5-2.2,4-2.7,6.5-2.2c5.3,1,6,9.4,12,7.8c0.2-2.4-0.6-6.6,3.2-6.3c1,0.1,2-0.3,3-0.3
	c2.2,0,4.3,0.8,6.5,0.8c1.4,0,3.1,0,4.3-0.8c1.5-0.9,2-2.6,3.8-3.1c3.3-0.9,5.4,3.4,8.8,2.6c3.1-0.7,5.4-3.3,8.9-2.1
	c3,1,5,3.3,8.5,2.4c3.4-0.9,6.3-0.2,9.7-0.2c3.3,0,6.6-0.3,10-0.2C400.9,65.7,400.1,64.5,399.3,64.3z"
            />
            <path d="M295.9,51.9C295.6,52.6,296.4,50.9,295.9,51.9L295.9,51.9z" />
          </symbol>
        </defs>
      </svg>
    </div>
  )
}

export default Svgdefs
