import { Link } from 'gatsby'
import React, { useState } from 'react'
import { useTheme } from '@material-ui/core/styles'

function Header() {
  const theme = useTheme()
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <header
      style={{ backgroundColor: theme?.palette.primary.main }}
      className="shadow"
    >
      <button
        className="fixed z-50 mb-4 bottom-0 mr-4 right-0 w-16 h-16 items-center block px-3 py-2 rounded-full bg-secondary-main md:hidden shadow-md hover:bg-primary-main transition duration-200 outline-none"
        onClick={() => toggleExpansion(!isExpanded)}
      >
        <div style={{ transform: 'rotate(90deg)' }}>
          <span className="border h-1 w-8 border-white mr-1"></span>
          <span className="border h-1 w-8 border-white ml-1"></span>
        </div>
      </button>

      <div className="flex flex-no-wrap items-center justify-between max-w-6xl px-4 mx-auto">
        <Link to="/">
          <svg
            className="h-16 hover:fill-white hover:stroke-white"
            style={{
              stroke: theme?.palette.primary.contrastText,
              fill: theme?.palette.primary.contrastText,
            }}
          >
            <use xlinkHref="#icon-kan-logo" />
          </svg>
        </Link>
        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto fixed bg-primary-main z-20 bottom-0 left-0 md:relative md:pr-8`}
        >
          {[
            {
              route: `/`,
              title: `Hem`,
            },
            // {
            //   route: `/galleri/`,
            //   title: `Galleri`,
            // },
            {
              route: `/biografi/`,
              title: `Biografi`,
            },
            {
              route: `/kontakt/`,
              title: `Kontakt`,
            },
          ].map((link) => (
            <Link
              style={{
                color: theme?.palette.primary.contrastText,
              }}
              className={`block mt-4 no-underline md:inline-block md:mt-0 md:ml-6 font-sans hover:text-white md:hover:underline transition ${
                isExpanded ? 'py-8 px-2 text-lg text-center' : ''
              }`}
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  )
}

export default Header
