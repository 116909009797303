import React from 'react'
import { useTheme } from '@material-ui/core'
import PropTypes from 'prop-types'

import Header from './header'
import Svgdefs from './Svgdefs'

function Layout({ children }) {
  const theme = useTheme()
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Svgdefs />
      <Header />

      <main className="flex-1 w-full">{children}</main>

      <footer className="bg-white">
        <nav className="flex justify-between max-w-4xl p-4 mx-auto text-sm md:p-8">
          <p
            style={{
              textColor: theme?.palette.primary.main,
            }}
          >
            Created by{` `}
            <a
              className="font-bold no-underline"
              href="https://alexwebb.se"
              target="_blank"
              rel="noopener noreferrer"
            >
              Alexander Nyström
            </a>
          </p>
        </nav>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
